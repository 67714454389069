import axiosBackend from './axiosBackend';

export async function takeCompleted(params) {
  try {
    const { data } = await axiosBackend.post('/takeCompleted', { ...params });
    return data;
  } catch (error) {
    return error.response ? error.response : error;
  }
}

export default {
  takeCompleted,
};
