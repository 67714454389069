<template>
  <b-link
    :to="{
      name: `${mediaLink}-details`,
      params: { itemKey: item.key, communityId: $route.params.communityId },
    }"
    class="text-dark mb-1 mt-1 w-100"
  >
    <div class=" d-flex d-md-block d-xl-flex more-card align-items-center ml-1">
      <div class="media-aside mr-1 d-flex align-items-center">
        <safe-img
          :class="!small ? 'large-thumbnail' : mediaType === 'audio' ? 'thumbnail-audio' : 'thumbnail '"
          :src="getImageResource(item.thumbnailURL)"
          :placeholder="placeholder"
          :alt="translatedTitle"
          :retry="5000"
        />
      </div>
      <div>
        <p class="title mb-25 mt-md-50 mt-xl-0">
          {{ translatedTitle }}
        </p>
        <small class="subtitle m-0 p-0 text-muted">
          {{ item.createdBy ? item.createdBy.name : '' }} 
        </small>
        <br v-if="item.createdBy">
        <small class="subtitle m-0 p-0 text-muted">
          {{ date }} 
        </small>
        <br>
        <small v-if="item.isCompletable" class="subtitle m-0 p-0 text-muted">
          <feather-icon
              size="16"
              :class="item.completedByMember ? 'item-completed' : ''"
              class="profile-icon mr-25"
              icon="CheckCircleIcon"
            />
          <span :class="item.completedByMember ? 'completed-text' : ''">
            {{ item.completedByMember ? $t('social.completed') : $t('social.not-completed') }}
          </span>
        </small>
      </div>
    </div>
  </b-link>
</template>

<script>
import { BMedia, BLink } from 'bootstrap-vue';
import SafeImg from '@core/components/safe-img/SafeImg.vue';
import { getImageResource } from '@/@core/utils/image-utils';
import MediaConfigurationMixin from '@/views/apps/media/mixins/MediaConfigurationMixin';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
import { PlaceholdersImageItems } from '@/views/apps/media/constants/MediaType';

export default {
  name: 'MediaRow',
  components: { BMedia, BLink, SafeImg },
  mixins: [MediaConfigurationMixin],
  props: {
    item: {
      type: Object,
      default: null,
    },
    mediaType: {
      type: String,
      required: true,
    },
    small: Boolean,
    widget: Boolean,
  },
  data() {
    return {
      isPlaceholder: false,
    };
  },
  computed: {
    app() {
      const app = Object.values(
        this.$store.getters[ENABLED_APPS_GETTERS.enabledApps]
      ).filter((item) => {
        if (item.key === this.mediaType) {
          return item.name;
        }
      });
      return app[0];
    },
    canShowCollaborator() {
      return this.app?.customization?.collaboratorDisplay;
    },
    date() {
      return this.$moment(this.item.createdAt).fromNow();
    },
    translatedTitle() {
      const translateName = translateTranslationTable(this.$store.state.locale.currentLocale, this.item.name);
      return translateName.replaceAll('_', ' ');
    },
    placeholder() {
      return PlaceholdersImageItems(this.mediaType);
    },
    mediaLink() {
      if (this.mediaType === 'file') return 'storage';
      return this.mediaType;
    },
  },
  methods: {
    getImageResource,
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.thumbnail {
  width: 80%;
}
.large-thumbnail {
  margin-left: 10px;
  border: 1px solid rgb(230, 230, 230);
}
.more-card {
  min-height: 100px;
  img {
    background-color: white;
    width: 100%;
    height: 100%;
  }
}
.media-aside {
  border-radius: 8px;
  max-height: 150px;
  max-width: 150px;
  overflow: hidden;
  background-color: red;
}

// using 'down' media query to override bootstrap's b-media
@include media-breakpoint-down(sm) {
  .media-row {
    align-items: center;
  }
  .media-aside {
    align-self: center !important;
    margin-right: 0;
    img {
      margin-right: 0 !important;
    }
  }
}
.item-completed {
  color: green !important;
  font-weight: 700;
  background-color: #b4f5b4;
}
.completed-text {
  color: green !important;
  font-weight: 700;
}
</style>
